/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Modal, TextInput, Button, Container } from '@mantine/core';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';

type ModalProps = {
  opened: boolean;
  onClose: () => void;
};

export const ModalText = styled.p`
  font-family: 'Raleway';
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
`;

export const ButtonText = styled.p`
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
`;

const ContactModal: React.FC<ModalProps> = ({ opened, onClose }) => {
  const [name, setName] = useState(''); // to store the contact info
  const [email, setEmail] = useState('');
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  // Function to validate contact information, for simplicity, let's assume it's just non-empty
  const isValidContact = () => email.trim() !== '';

  useEffect(() => {
    setIsReadyToSubmit(name !== '' && email !== '');
  }, [name, email]);

  const buttonStyle = {
    backgroundColor: `${isReadyToSubmit ? 'black' : 'grey'}`,
    color: 'white',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',
    justifyContent: 'center',
    height: '35px',
    width: '125px',
  };

  const handleClick = async () => {
    // insert data into supabase
    try {
      const { data, error } = await supabase
        .from('waitlist')
        .insert([
          {
            name: name,
            email: email,
          },
        ])
        .select();
      if (error) {
        console.error('Error inserting data:', error.message);
      }
    } catch (error) {
      console.error('Error inserting data:', error);
    }
    setName('');
    setEmail('');
    onClose();
  };

  return (
    <>
      <Modal
        title=""
        opened={opened}
        onClose={onClose} // provide an empty function so it doesn't close
        closeOnClickOutside={true}
        withCloseButton={false} // hide the close button
        size="55%" // adjust size as needed
        overlayProps={{ color: 'blue' }}
      >
        <ModalText>Be the first to use ester</ModalText>
        <TextInput
          label="Name"
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          placeholder="Felicia Chen"
          style={{ padding: '10px', fontFamily: 'Raleway' }}
        />
        <TextInput
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value)}
          placeholder="felicia@ester.app"
          style={{ padding: '10px', fontFamily: 'Raleway' }}
        />
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          <Button
            style={buttonStyle}
            onClick={() => handleClick()}
            disabled={!isValidContact()}
          >
            <ButtonText>Submit</ButtonText>
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default ContactModal;
