/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, Grid, Col } from '@mantine/core';
import redGif from '../images/red.gif';
import bobaGif from '../images/boba.gif';
import aerocraftGif from '../images/aerocrafts.gif';
import widgetwaveGif from '../images/widgetwave.gif';

const slidesData = [
  {
    text: 'sales deck for my hollywood-inspired makeup brand ',
    gif: redGif,
  },
  {
    text: 'explanation for our airplane robotics startup',
    gif: aerocraftGif,
  },
  {
    text: 'landing page for my french-inspired boba brand',
    gif: bobaGif,
  },
  {
    text: 'four quadrant poster for my widget company',
    gif: widgetwaveGif,
  },
];

const Carousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [typingCompleted, setTypingCompleted] = useState(false);

  useEffect(() => {
    if (charIndex < slidesData[currentIndex].text.length) {
      const timerId = setTimeout(() => {
        setCharIndex(charIndex + 1);
      }, 100); // Adjust speed of typing
      return () => clearTimeout(timerId);
    } else {
      setTypingCompleted(true);
    }
  }, [charIndex, currentIndex]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    beforeChange: (_: any, next: any) => {
      setCurrentIndex(next);
      setCharIndex(0);
      setTypingCompleted(false);
    },
  };

  return (
    <Slider {...settings}>
      {slidesData.map((slide, index) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container>
            <Grid>
              <Col span={4}>
                <div
                  style={{
                    fontSize: '2em',
                    textAlign: 'left',
                    fontWeight: 100,
                  }}
                >
                  {slidesData[currentIndex].text.substring(0, charIndex)}
                </div>
              </Col>
              <Col span={8}>
                {typingCompleted && (
                  <div className="image">
                    <img
                      src={slide.gif}
                      alt=""
                      style={{ maxWidth: '100%', maxHeight: '80vh' }}
                    />
                  </div>
                )}
              </Col>
            </Grid>
          </Container>
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
