import React from 'react';
import logo from '../images/ester-transparent.png';
import { Image } from '@mantine/core';

const Header = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light"
      style={{ fontFamily: 'Raleway', fontWeight: 300 }}
    >
      <div className="container" style={{ height: '50px' }}>
        <a className="navbar-brand" href="/">
          <Image
            src={logo}
            fit="contain"
            style={{ height: 'auto', width: '300px' }}
          ></Image>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="/" style={{ fontSize: 16 }}>
                Home
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
