/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Button } from '@mantine/core';
import Carousel from '../components/Carousel';
import styled from 'styled-components';
import ContactModal from '../components/ContactModal';

const buttonStyle = {
  backgroundColor: 'black',
  color: 'white',
  border: 'none',
  fontSize: '18px',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s',
  justifyContent: 'center',
  height: '45px',
  width: '200px',
};

export const Text = styled.p`
  font-family: 'Raleway';
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 15px;
`;

const HomePage = () => {
  const [modalOpened, setModalOpen] = useState(false);

  return (
    <div>
      <Container size={400} style={{ padding: 40 }}>
        <ContactModal
          opened={modalOpened}
          onClose={() => setModalOpen(false)}
        ></ContactModal>
      </Container>
      {/* Hero Section with Slider */}
      <Container size="s" style={{ padding: '30px' }}>
        <h1 style={{ textAlign: 'center', fontWeight: 100 }}>
          build custom presentations using <i>speech</i>, <i>image</i>, and{' '}
          <i>text</i>
        </h1>
      </Container>

      <Container size="xl">
        <Carousel></Carousel>
      </Container>

      <Container style={{ position: 'fixed', bottom: '9em', left: '45%' }}>
        <Button
          type="submit"
          style={buttonStyle}
          onClick={() => setModalOpen(true)}
        >
          <Text>Join the waitlist</Text>
        </Button>
      </Container>
    </div>
  );
};

export default HomePage;
